.spec {
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-wrap: wrap;
	$this: &;
	&-item {
		position: relative;
		width: 48%;
		min-height: 240px;
		margin-bottom: 15px;
		color: #fff;
		&--processor {
			background: #39B34A;
			margin-right: 4%;
		}
		&--memory {
			background: #8BC53F;
		}
		&--storage {
			background: #4D4D4D;
			width: 100%;
		}
		&-title {
			position: absolute;
			bottom: 20px;
			left: 23px;
			&-text {
				font-family: "Gotham-Bold";
				font-size: 16px;
				margin: 0;
				padding: 0;
			}
			&-img {
				&--processor {
					width: 44px;
					margin-bottom: 5px;
				}
				&--memory {
					width: 47px;
					margin-bottom: 14px;
				}
				&--storage {
					width: 34px;
					margin-bottom: 7px;
				}
			}
		}
		&-unit {
			position: absolute;
			top: 19px;
			right: 26px;
			display: flex;
			&-number {
				font-size: 72px;
				margin: 0;
				line-height: 1;
				text-align: right;
				font-family: "Gotham-Thin";
				span {
					display: block;
					font-size: 17px;
				}
			}
			&-control {
				margin: 24px 0 0 8px;
				&-btn {
					background: none;
					border: none;
					display: block;
					outline: none;
					cursor: pointer;
					padding: 0;
					img {
						width: 13px;
					}
				}
			}
		}
	}
	&-big {
		max-width: 516px;
		#{$this} {
			&-item {
				width: 48.5%;
				min-height: 250px;
				&--processor {
					margin-right: 3%;
				}
				&--storage {
					width: 100%;
				}
			}
		}
	}
	&-product {
		width: 500px;
	}
}


@media screen and (max-width: 960px) {
	.spec {
		width: 100%;
		max-width: 240px;
		margin: 0 auto 26px;
		$this: &;
		&-item {
			width: auto;
			min-width: 116px;
			min-height: 116px;
			margin-bottom: 6px;
			&--processor {
				margin-right: 3%;
			}
			&--storage {
				width: 240px;
			}
			&-title {
				bottom: 12px;
				left: 11px;
				&-text {
					font-size: 10px;
				}
				&-img {
					&--processor {
						width: 21px;
						margin-bottom: 5px;
					}
					&--memory {
						width: 22px;
						margin-bottom: 10px;
					}
					&--storage {
						width: 16px;
						margin-bottom: 6px;
					}
				}
			}
			&-unit {
				top: 9px;
				right: 12px;
				&-number {
					font-size: 34px;
					span {
						font-size: 10px;
					}
				}
				&-control {
					margin: 12px 0 0 4px;
					&-btn {
						height: 3px;
						margin-bottom: 4px;
						img {
							width: 6px;
							vertical-align: top;
						}
					}
				}
			}
		}
		&-big {
			width: 100%;
			max-width: 294px;
			#{$this} {
				&-item {
					min-height: 142px;
				}
			}
		}
	}
}