.hero {
	min-height: 794px;
	position: relative;
	z-index: 999;
	&-wrapper {
		position: relative;
	}
	&-title {
		position: absolute;
		top: 50%;
		left: 0;
		font-family: "Gotham-Black";
		font-size: 100px;
		width: 48%;
		transform: translateY(-50%);
		line-height: .85;
		margin: 0;
		font-weight: normal;
		strong {
			color: #8BC53F;
			font-style: normal;
		}
	}
	&-info {
		width: 20.736196319018405%;
		position: absolute;
		top: 50%;
		right: 22%;
		transform: translateY(-50%);
		&-text {
			font-family: "Gotham-Light";
			font-size: 12px;
			line-height: 1.16;
			color: #4D4D4D;
			margin: 0 0 20px;
		}
		&-btn {
			display: inline-block;
			background: #E5E5E5;
			padding: 8px 20px;
			font-size: 14px;
			text-decoration: none;
			color: #000;
			&:hover {
				background: #8BC53F;
				color: #fff;
			}
		}
	}
	&-cloud {
		position: absolute;
		&-one {
			width: 12%;
			top: 6%;
			left: 43%;
			max-width: 233px;
		}
		&-two {
			width: 36%;
			top: 13%;
			right: -10%;
			max-width: 600x;
		}
		&-three {
			width: 23%;
			bottom: -10%;
			left: 49%;
			max-width:438px;
		}
		&-four {
			width: 13%;
			bottom: 4%;
			left: 24%;
			max-width:438px;
		}
	}
	&-scroll-btn {
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

@media screen and (max-width: 1259px) {
	.hero {
    min-height: initial;
    min-height: auto;
		padding-top: 210px;
		padding-bottom: 301px;
		z-index: 999;
		&-title {
			position: static;
			width: 100%;
			transform: translateY(0);
			margin: 0 0 40px;
			font-weight: normal;
		}
		&-info {
			width: 60.736196319018405%;
			position: static;
			transform: translateY(0);
		}
	}
}

@media screen and (max-width: 960px) {
	.hero {
		min-height: initial;
		min-height: auto;
		padding: 132px 0 162px 0;
		&-wrapper {
			background: #fff;
			padding: 0;
		}
		&-title {
			position: static;
			font-size: 40px;
			width: 100%;
			transform: translateY(0);
			padding: 0 35px;
			margin: 0 0 105px;
		}
		&-info {
			width: 100%;
			position: static;
			transform: translateY(0);
			background: #F3F4F6;
			padding: 74px 47px 48px;
			text-align: center;
			box-sizing: border-box;
			&-btn {
				background: #8CC63F;
				padding: 5px 13px;
				font-size: 10px;
				color: #fff;
			}
		}
		&-cloud {
			&-one {
				width: 39%;
				top: 0;
				left: 54%;
				max-width: 146px;
			}
			&-two {
				width: 39%;
				top: 161px;
				right: -16%;
				max-width: 300px;
			}
			&-three {
				width: 29%;
				bottom: 47px;
				right: 21%;
				left: auto;
				max-width: 146px;
			}
			&-four {
				width: 39%;
				top: 270px;
				left: -50px;
				max-width: 180px;
				z-index: 999;
			}
		}
		&-scroll-btn {
			display: none;
		}
	}
}


.solutions {
	padding: 184px 0 0 0;
	display: flex;
	justify-content: space-between;
	position: relative;
	&-cloud {
		display: none;
		position: absolute;
		bottom: 49px;
		right: 0;
    transform: rotate(-90deg) translate(40px, 90px);
		width: 215px;
	}
	&-wrapper {
		background-color: #fff;
		min-height: 1270px;
    background-position: center bottom;
		background-repeat: no-repeat;
		background-size: 100% auto;
		position: relative;
	}
	&-text-group {
		width: 39%;
		padding: 0 30px 0 0;
		box-sizing: border-box;
	}
	&-title {
		font-family: "Gotham-Black";
    font-size: 97px;
		color: #8BC53F;
		font-weight: normal;
		margin: 0 0 81px;
		word-wrap: break-word;
		overflow-wrap: break-word;
		line-height: 1.06;
		&-vertical {
			position: absolute; 
			left: 40px;
			bottom: 0;
			z-index: 1;
			transform-origin: 0 100%;
			transform: rotate(-90deg);
			margin: 0;
			font-size: 20px;
			strong {
				font-family: "Gotham-Black";
				font-weight: normal;
			}
		}
	}
	&-info {
		font-size: 12px;
		line-height: 1.16;
		margin: 0;
		max-width: 400px;
		font-family: "Gotham-Light";
	}
	&-plans {
		width: 61%;
    display: flex;
    flex-wrap: wrap;
		justify-content: flex-end;
		align-items: flex-start;
		margin: 60px 0 0;
		min-height: 470px;
		&-item {
			width: 89.2%;
			margin: 0 0 33px;
			background: transparent linear-gradient(90deg, #FFFFFF00 0%, #F8F8F83D 14%, #F2F2F282 30%, #ECECECB8 46%, #E9E9E9DF 59%, #E6E6E6F6 70%, #E6E6E6 78%) 0% 0% no-repeat padding-box;
			transition: width .2s;
			&-inner {
				display: flex;
				justify-content: flex-end;
				min-height: 145px;
				text-decoration: none;
			}
			&:hover {
				width: 100%;
				.solutions {
					&-plans {
						&-item {
							&-img {
								width: 27%;
							}
							&-name {
								color: #8BC53F;
								width: 26%;
							}
							&-desc {
								width: 24%;
							}
							&-price {
								background: #4D4D4D;
								max-width: 223px;
							}
						}
					}
				}
			}
			> * {
				margin: 0;
			}
			&-img {
				display: flex;
				align-items: center;
				width: 22%;
				img {
					padding: 20px 0 0;
					max-width: 158px;
				}
				transition: width .2s;
			}
			&-name {
				display: flex;
				align-items: center;
				font-family: "Gotham-Black";
				font-size: 2.856vw;
				color: #3D3D3C;
				width: 32%;
				word-wrap: break-word;
				overflow-wrap: break-word;
				transition: width .2s;
				margin: 0;
			}
			&-desc {
				display: flex;
				align-items: center;
				font-family: "Gotham-Bold";
				font-size: 11px;
				line-height: 1;
				color: #8BC53F;
				width: 20%;
				transition: width .2s;
				padding: 0 20px 0 0;
				box-sizing: border-box;
			}
			&-price {
				font-family: "Gotham-Black";
				display: flex;
				align-items: center;
				justify-content: center;
				background: #8BC53F;
				width: 25.2548131370328%;
				max-width: 223px;
				font-size: 1.5vw;
				color: #fff;
			}
		}
	}
}


@media screen and (min-width: 1630px) {
	.solutions {
		&-title {
			font-size: 108px;
		}
		&-info {
			font-size: 12px;
		}
		&-plans {
			&-item {
				&-name {
					font-size: 56px;
				}
				&-desc {
					font-size: 11px;
				}
				&-price {
					font-size: 24px;
				}
			}
		}
	}
}

@media screen and (max-width: 1352px) {
	.solutions {
		display: block;
		&-wrapper {
			padding-bottom: 100px;
		}
		&-text-group {
			width: 100%;
			padding: 0 30px 0 0;
			box-sizing: border-box;
			min-height: initial;
			min-height: auto;
		}
		&-plans {
			width: 100%;
			padding-bottom: 200px;
			min-height: initial;
			min-height: auto;
			&-item {
				margin: 0 auto 33px;
				&:last-child {
					margin: 0 auto 0;
				}
			}
		}
	}
}


@media screen and (max-width: 960px) {
	.solutions {
		padding: 0;
		display: block;
		justify-content: space-between;
		position: relative;
		&-cloud {
			display: block;
		}
		&-wrapper {
			background-color: #fff;
			min-height: initial;
			min-height: auto;
			padding-bottom: 290px;
			background-size: 200% auto;
		}
		&-text-group {
			width: 100%;
			padding: 0;
		}
		&-title {
			font-size: 38px;
			margin: 0 0 36px;
			&-vertical {
				left: 20px;
				font-size: 10px;
			}
		}
		&-info {
			margin: 0 0 50px;
			max-width: initial;
			max-width: auto;
		}
		&-plans {
			width: 100%;
			display: block;
			margin: 0;
			padding: 0 0 130px;
			min-height: initial;
			min-height: auto;
			&-item {
				width: 100%;
				margin: 0 0 20px;
				transition: none;
				&-inner {
					justify-content: space-between;
					min-height: initial;
					min-height: auto;
					text-decoration: none;
				}
				&:hover {
					.solutions {
						&-plans {
							&-item {
								&-img {
									width: 17%;
								}
								&-name {
									width: 32%;
									color: #3D3D3C;
								}
								&-price {
									width: 33%;
									color: #fff;
									background: #8BC53F;
								}
							}
						}
					}
				}
				&-img {
					width: 17%;
					img {
						padding: 0;
					}
					transition: none;
				}
				&-name {
					font-size: 23px;
					transition: none;
					padding: 10px 0;
				}
				&-desc {
					display: none;
				}
				&-price {
					width: 33%;
					font-size: 12px;
				}
			}
		}
	}
}


.benefits {
	padding: 0 0 248px 0;
	&-wrapper {
		position: relative;
		overflow: hidden;
	}
	&-cloud {
		width: 33%;
		position: absolute;
		bottom: 0;
		right: 0;
    transform: translate(21%, 45%);
	}
	&-title {
		font-family: "Gotham-Black";
		font-size: 97px;
		color: #8BC53F;
		font-weight: normal;
		margin: 0 0 131px;
		word-wrap: break-word;
		overflow-wrap: break-word;
	}
	&-list {
		display: flex;
		justify-content: space-between;
	}
	&-item {
		width: 22%;
		max-width: 352px;
		&-img {
			margin: 0 0 59px;
			&--express {
				width: 99px;
			}
			&--desktop {
				width: 97px;
			}
			&--upgrade {
				width: 110px;
			}
			&--security {
				width: 72px;
			}
		}
		&-info {
			margin: 0;
			padding: 0;
			&-title {
				font-size: 20px;
				font-family: "Gotham-Bold";
				color: #8BC53F;
				margin: 0 0 38px;
				span {
					display: block;
				}
			}
			&-text {
				font-size: 12px;
				font-family: "Gotham-Light";
				margin: 0;
				padding: 0;
			}
		}
	}
}


@media screen and (max-width: 1352px) {
	.benefits {
		&-list {
			flex-wrap: wrap;
		}
		&-item {
			width: 44%;
			max-width: initial;
			max-width: auto;
			margin: 0 0 100px 0;
			&:nth-child(1n+3) {
				margin: 0;
			}
		}
	}
}

@media screen and (max-width: 960px) {		
	.benefits {
		padding: 0 0 134px;
		&-cloud {
			width: 80%;
		}
		&-title {
			font-size: 38px;
			margin: 0 0 130px;
		}
		&-list {
			display: block;
			padding: 0 27px;
		}
		&-item {
			width: 100%;
			max-width: initial;
			max-width: auto;
			margin: 0 0 20px;
			&:nth-child(1n+3) {
				margin: 0 0 20px;
			}
			&:last-child {
				margin: 0;
			}
			&-img {
				margin: 0 26px 0 0;
				&--express ,
				&--desktop ,
				&--upgrade ,
				&--security {
					width: 26px;
				}
			}
			&-info {
				margin: 0;
				padding: 0;
				&-title {
					display: flex;
					align-items: center;
					font-size: 14px;
					position: relative;
					span {
						display: block;
						br {
							display: none;
						}
					}
					&::before {
						position: absolute;
						top: 50%;
						right: 0;
						content: "";
						display: block;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 4px 4px 0 4px;
						border-color: #8AC43F transparent transparent transparent;
						transform: translateY(-50%);
					}
					&--active {
						&::before {
							transform: translateY(-50%) rotate(90deg);
						}
					}
				}
				&-text {
					margin: 0;
					padding: 0;
					max-height: 0;
					overflow: hidden;
					transition: max-height .6s;
					&--active {
						max-height: 240px;
					}
				}
			}
		}
	}
}


.region {
	position: relative;
	min-height: 908px;
	&-wrapper {
		padding-top: 172px;
	}
	&-title {
		font-family: "Gotham-Black";
		font-size: 97px;
		color: #8BC53F;
		font-weight: normal;
		margin: 0 0 68px;
		word-wrap: break-word;
		overflow-wrap: break-word;
		&-group {
			width: 348px;
		}
		&-vertical {
			position: absolute;
			left: 40px;
			bottom: 149px;
			transform-origin: 0 100%;
			transform: rotate(-90deg);
			margin: 0;
			font-family: "Gotham-Black";
			font-size: 20px;
		}
	}
	&-text {
		font-size: 12px;
		line-height: 1.16;
		margin: 0;
		max-width: 400px;
		font-family: "Gotham-Light";
	}
	&-map {
		position: absolute;
		top: 0;
		right: 0;
		/* 1177 */
		width: calc(100% - 453px);
		&-img {
			&-regions {
				width: 100%;
				vertical-align: bottom;
			}
			&-location {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 29%;
				transform: translate(-50%, -50%);
				cursor: pointer;
			}
		}
		&-regions {
			position: absolute;
			display: block;
			width: 6.287170773152082%;
			&-tokyo {
				left: 84.706881903143585%;
				top: 39.450867052023121%;
			}
			&-la {
				left: 8.496176720475786%;
				top: 35.982658959537572%;
			}
			&-am {
				left: 45.964316057774002%;
				top: 33.092485549132948%;
			}
		}
	}
	&-icons {
		position: absolute;
		top: 464px;
		left: 260px;
		margin: 0;
		padding: 0;
		&-locations ,
		&-regions {
			display: flex;
			align-items: center;
			font-size: 12px;
			font-family: "Gotham-Bold";
			span {
				margin: 0 25px 0 0;
			}
		}
		&-locations {
			margin: 0 0 24px;
			span {
				text-align: center;
				width: 50px;
				img {
					width: 100%;
					max-width: 22px;
				}
			}
		}
		&-regions {
			margin: 0 0 50px;
			span {
				img {
					width: 100%;
					max-width: 50px;
				}
			}
		}
	}
	&-location {
		position: absolute;
		bottom: 149px;
		left: 260px;
		&-item {
			width: 50%;
			border-left: 1px solid #8BC53F;
			padding: 21px 32px;
			margin: 0;
			dt ,dd {
				font-size: 12px;
				margin: 0;
				padding: 0;
			}
			dt {
				font-family: "Gotham-Bold";
			}
			dd {
				font-family: "Gotham-Light";
			}
		}
		&-inner {
			width: 500px;
			display: flex;
		}
	}
}

@media screen and (max-width: 1629px) {
	.region {
		min-height: initial;
		min-height: auto;
		&-wrapper {
			padding-top: 172px;
			padding-bottom: 149px;
		}
		&-title {
			&-group {
				width: 100%;
				margin: 0 0 80px;
			}
			&-vertical {
				bottom: 0;
			}
		}
		&-map {
			position: relative;
			width: 100%;
			margin: 0 0 40px;
		}
		&-icons {
			position: static;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			&-locations ,
			&-regions {
				margin: 0 36px 40px;
				span {
					width: auto;
				}
			}
		}
		&-location {
			position: static;
			display: flex;
			justify-content: center;
			&-item {
				width: auto;
			}
			&-inner {
				width: auto;
				display: flex;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.region {
		min-height: initial;
		min-height: auto;
		&-wrapper {
			padding-top: 63px;
			padding-bottom: 69px;
		}
		&-title {
			font-size: 38px;
			margin: 0 0 34px;
			&-group {
				box-sizing: border-box;
				margin: 0 0 23px;
			}
			&-vertical {
				bottom: 0;
				font-size: 10px;
			}
		}
		&-icons {
			margin: 0 0 31px;
			&-locations {
				margin: 0 35px 0 0;
				span {
					width: auto;
					margin: 0 19px 0 0;
					img {
						width: 15px;
					}
				}
			}
			&-regions {
				margin: 0;
				span {
					margin: 0 19px 0 0;
					img {
						width: 35px;
					}
				}
			}
		}
		&-location {
			position: static;
			display: flex;
			justify-content: center;
			padding-left: 60px;
			display: block;
			&-item {
				width: 50%;
				padding: 11px 22px;
				box-sizing: border-box;
				dt ,dd {
					font-size: 10px;
				}
			}
			&-inner {
				width: 100%;
				display: flex;
			}
		}
	}
}

.faq {
	padding: 140px 0 270px;
	&-wrapper {
		position: relative;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: 100% auto;
	}
	&-cloud {
		position: absolute;
    top: 0;
		right: 0;
    transform: translateY(-39%);
		width: 14%;
	}
	&-title {
		font-family: "Gotham-Black";
		font-size: 40px;
		color: #8BC53F;
		margin: 0 0 100px;
		padding: 0 0 0 103px;
		position: relative;
		&::before ,
		&::after {
			content: '';
			display: block;
			position: absolute;
		}
		&::before {
			width: 67px;
			height: 67px;
			background: #8BC53F;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
		&::after {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 9px 8.5px 0 8.5px;
			border-color: #fff transparent transparent transparent;
			top: 50%;
			left: 25px;
			transform: translateY(-50%);
		}
	}
	&-list {
		width: 45%;
		margin: 0;
		padding: 0;
		&-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			counter-reset:count;
		}
	}
	&-item {
		width: 100%;
		margin: 0 0 40px; 
		padding: 0;
		list-style-type: none;
		counter-increment:count;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		&:last-child {
			margin: 0;
		}
		&::before {
			display: block;
			content:counter(count);
			font-size: 132px;
			font-family: "Gotham-Thin";
			color: #8BC53F;
			width: 108px;
		}
		&-inner {
			width: calc(100% - 108px);
			display: flex;
			align-items: center;
			margin: 0;
			padding: 0;
		}
		&-title ,
		&-text {
			margin: 0;
			padding: 0;
		}
		&-title {
			font-family: "Gotham-Bold";
			font-size: 12px;
			width: 171px;
			margin: 0 76px 0 0;
			color: #8BC53F;
		}
		&-text {
			font-family: "Gotham-Light";
			font-size: 12px;
			width: calc(100% - 247px);
		}
	}
}

@media screen and (max-width: 1399px) {
	.faq {
		&-list {
			width: 100%;
			margin: 0 0 40px;
			&:last-child {
				margin: 0;
			}
		}
	}
}


@media screen and (max-width: 960px) {
	.faq {
		padding: 35px 0 50px;
		&-title {
			font-size: 25px;
			margin: 0 0 81px;
			padding: 0 0 0 52px;
			&::before {
				width: 33px;
				height: 33px;
			}
			&::after {
				border-width: 4px 4px 0 4px;
				left: 13px;
			}
		}
		&-list {
			width: 100%;
			&-wrapper {
				display: block;
			}
		}
		&-item {
			margin: 0 0 20px;
			&::before {
				content: none;
			}
			&-inner {
				width: 100%;
				display: block;
			}
			&-title {
				position: relative;
				padding: 0 82px 0 50px;
				margin: 0;
				box-sizing: border-box;
				height: 59px;
				width: 100%;
				display: flex;
				align-items: center;
				&::before {
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					display: block;
					content:counter(count);
					font-size: 56px;
					font-family: "Gotham-Thin";
					color: #8BC53F;
					width: 55px;
				}
				&::after {
					position: absolute;
					top: 50%;
					right: 0;
					content: "";
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 4px 4px 0 4px;
					border-color: #8AC43F transparent transparent transparent;
					transform: translateY(-50%);
				}
				&--active {
					&::after {
						transform: translateY(-50%) rotate(90deg);
					}
				}
			}
			&-text {
				padding-top: 12px;
				width: 100%;
				max-height: 0;
				overflow: hidden;
				transition: max-height .6s;
				&--active {
					max-height: 122px;
				}
			}
		}
	}
}