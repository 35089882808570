.common-layout {
	padding: 285px 0 0 0;
	&-lower {
		padding: 150px 0 0 0;
	}
}

@media screen and (max-width: 1590px) {
	.common-layout {
		&-lower {
			padding: 196px 0 0 0;
		}
	}
}

@media screen and (max-width: 960px) {
	.common-layout {
		padding: 103px 0 0 0;
		&-lower {
			padding: 147px 0 0 0;
		}
	}
}

.common-layout-right {
	width: 100%;
	padding: 160px 242px 320px;
	background: #fff;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
	&-inner {
		width: 100%;
		max-width: 1430px;
		margin: 0 auto;
	}
	&-back {
		position: absolute;
		top: 150px;
		left: 140px;
	}
	&-cloud {
		width: 33%;
		position: absolute;
		bottom: 0;
		right: 0;
		transform: translate(2%, 42%,);
	}
}

@media screen and (max-width: 960px) {
	.common-layout-right {
		padding: 47px 47px 155px;
		&-back {
			top: 45px;
			left: 36px;
		}
		&-cloud {
			width: 70%;
			max-width: 310px;
			transform: translate(0, 32%,);
		}
	}
}

.common-layout-contact {
	background-color: #fff;
	padding: 160px 150px 320px 252px;
	position: relative;
	overflow: hidden;
	&-inner {
		width: 100%;
		margin: 0 auto;
	}
	&-back {
		position: absolute;
		top: 150px;
		left: 140px;
	}
	&-cloud {
		width: 33%;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translate(-18%, 20%);
	}
}


@media screen and (max-width: 960px) {
	.common-layout-contact {
		padding: 47px 47px 155px;
		&-back {
			top: 45px;
			left: 36px;
		}
		&-cloud {
			max-width: 660px;
			width: 90%;
			transform: translate(-20%, 70%) scale(-1, 1);
		}
	}
}


.wrapper {
	width: 100%;
	padding: 0 36px;
	box-sizing: border-box;
	&--glay {
		background: #F4F5F7;
	}
	&--cloud {
		background: #F4F5F7;
	}
	&--white {
		background: #fff;
	}
	&--green {
		background: #8AC43F;
	}
	&-inner {
		width: 100%;
		max-width: 1630px;
		margin: 0 auto;
	}
}

.coming-soon {
	&-wrapper {
		height: 100vh;
		overflow: hidden;
		position: relative;
	}
	&-inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		z-index: 999;
		width: 100%;
		overflow: hidden;
	}
	&-logo {
		width: 100%;
		max-width: 200px;
		margin: 0 auto;
		img {
			width: 100%;
		}
	}
	&-title {
		font-family: "Gotham-Black";
		font-size: 60px;
		margin: 0;
		span {
			color: #8BC53F;
		}
	}
	&-cloud {
		position: absolute;
		&-one {
			width: 14%;
			top: 6%;
			left: 33%;
			max-width: 233px;
			opacity: .7;
		}
		&-two {
			width: 36%;
			top: 13%;
			right: -11%;
			filter: blur(24px);
			max-width: 600x;
			opacity: .8;
		}
		&-three {
			width: 26%;
			bottom: -6%;
			left: 44%;
			max-width:438px;
		}
		&-four {
			width: 16%;
			bottom: 4%;
			left: 4%;
			max-width:438px;
			filter: blur(16px);
			opacity: .6;
		}
	}
}

@media screen and (max-width: 599px) {
	.coming-soon {
		&-title {
			font-family: "Gotham-Black";
			font-size: 40px;
			margin: 0;
			span {
				color: #8BC53F;
			}
		}
		&-cloud {
			&-one {
				width: 115px;
				top: 54px;
				left: 8%;
			}
			&-two {
				width: 300px;
				top: 60px;
				right: -140px;
				filter: blur(24px);
			}
			&-three {
				width: 215px;
				bottom: -40px;
				left: 40%;
			}
			&-four {
				width: 130px;
				bottom: 80px;
				left: -10%;
				filter: blur(16px);
			}
		}
	}
}


.header {
	height: 285px;
	background: #fff;
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	&-inner {
		display: flex;
		align-items: center;
		position: relative;
		padding: 0 112px 0 0;
    max-width: 1518px;
	}
	&-logo {
		width: 159px;
    min-width: 159px;
		height: 40px;
		font-size: 12px;
		margin: 0 10% 0 0;
		border-radius: 4px;
	}
	&-menu {
		display: flex;
		align-items: center;
		list-style: none;
		text-align: center;
		margin: 0;
		padding: 0;
		flex-wrap: wrap;
		&-wrapper {
			&--sp {
				display: none;
			}
		}
		&--sp {
			display: none;
		}
		&-item {
			margin: 5px 70px 5px 0;
			font-size: 12px;
			letter-spacing: 2.43px;
			&:last-child {
				margin: 0;
			}
			a {
				display: block;
				color: #4D4D4D;
				text-decoration: none;
				transform-origin: 50% 50%;
				transition: font-family .5s, transform .5s;
				cursor: pointer;
				&:hover {
					font-family: "Gotham-Black";
					color: #8BC53F;
					transform: scale(1.1);
					line-height: 1.2;
				}
			}
		}
	}
	&-login {
		cursor: pointer;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
	&-info {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		&-btn {
			padding: 11px 17px !important;
			margin: 0 40px 0 0 !important;
			font-family: "Gotham-Black";
			text-decoration: none;
		}
		&-user {
			width: 62px;
			height: 62px;
			background: #F4F5F7;
			border-radius: 50%;
			box-sizing: border-box;
			position: relative;
			overflow: hidden;
			border: 3px solid #fff;
			&:hover {
				border: 3px solid #8BC53F;
			}
			&-icon {
				width: 100%;
				&-default {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 30px;
				}
			}
		}
		&--sp {
			position: static;
			transform: translateY(0);
			display: flex;
			align-items: center;
			margin-right: 20px;
		}
	}
	&-lower {
		background: #FAFAFA;
		height: 150px;
	}
}

@media screen and (max-width: 1590px) {
	.header {
		&-lower {
			background: #fff;
		}
		&-inner {
			justify-content: space-between;
			padding: 0;
		}
		&-menu {
			display: block;
			align-items: flex-start;
			text-align: left;
			margin: 0 0 226px;
			&-wrapper {
				display: none;
				background: #f2f2f2;
				position: fixed;
				top: 0;
				left: 0;
				height: calc(100vh - 285px);
				width: 100vw;
				box-sizing: border-box;
				overflow-y: scroll;
				transform: translateY(-100%);
				transition: transform .6s;
				&--sp {
					display: block;
					z-index: 9998;
				}
				&-lower {
					height: calc(100vh - 196px);
				}
				&--active {
					transform: translateY(285px);
					&-lower {
						transform: translateY(196px);
					}
				}
			}
			&-inner {
				padding: 22px 36px;
			}
			&--sp {
				display: flex;
				&--button {
					background: none;
					border: none;
					appearance: none;
					width: 66px;
					height: 56px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					outline: none;
					&-inner {
						position: relative;
						width: 36px;
						height: 24px;
						background: none;
						border: none;
						appearance: none;
						display: inline-block;
						transition: all .4s;
						box-sizing: border-box;
						&--active {
							span {
								&:nth-of-type(1) {
									transform: translateY(12px) rotate(-45deg);
								}
								&:nth-of-type(2) {
									opacity: 0;
								}
								&:nth-of-type(3) {
									transform: translateY(-11px) rotate(45deg);
								}
							}
						}
						span {
							display: inline-block;
							transition: all .4s;
							box-sizing: border-box;
							position: absolute;
							left: 0;
							width: 100%;
							height: 2px;
							background-color: #8cc63f;
							&:nth-of-type(1) {
								top: 0;
							}
							&:nth-of-type(2) {
								top: 10px;
							}
							&:nth-of-type(3) {
								bottom: 0;
							}
						}
					}
				}
				&-login {
					background: none;
					border: none;
					margin: 0 6px 0 0;
					padding: 0;
					width: 66px;
					height: 56px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					outline: none;
					img {
						width: 32px;
					}
				}
			}
			&-item {
				margin: 0 0;
				font-size: 12px;
				letter-spacing: 2.43px;
				&:last-child {
					margin: 0;
				}
				a {
					display: inline-block;
					color: #4D4D4D;
					text-decoration: none;
					padding: 22px 0;
					border-bottom: 1px solid #8cc63f;
					transition: none;
					cursor: pointer;
					&:hover {
						font-family: "Gotham-Black";
						transform: scale(1);
					}
				}
			}
		}
		&-login {
			position: static;
			display: inline-block;
			padding: 12px 30px;
			transform: translateY(0);
			font-size: 12px;
		}
		&-info {
			display: none;
		}
	}
}


@media screen and (max-width: 960px) {
	.header {
		height: 103px;
		&-logo {
			width: 23.4%;
			min-width: 88px;
			height: auto;
		}
		&-menu {
			&-wrapper {
				height: calc(100vh - 103px);
				&-lower {
					height: calc(100vh - 147px);
				}
				&--active {
					transform: translateY(103px);
					&-lower {
						transform: translateY(147px);
					}
				}
			}
			&--sp {
				&--button {
					width: 33px;
					height: 28px;
					&-inner {
						width: 18px;
						height: 12px;
						&--active {
							span {
								&:nth-of-type(1) {
									transform: translateY(6px) rotate(-45deg);
								}
								&:nth-of-type(3) {
									transform: translateY(-4px) rotate(45deg);
								}
							}
						}
						span {
							&:nth-of-type(2) {
								top: 5px;
							}
						}
					}
				}
				&-login {
					width: 33px;
					height: 28px;
					img {
						width: 16px;
					}
				}
			}
			&-item {
				a {
					font-size: 10px;
				}
			}
		}
		&-login {
			font-size: 10px;
		}
		&-info {
			&-btn {
				padding: 4px 6px !important;
				margin: 0 22px 0 0 !important;
			}
			&-user {
				width: 30px;
				height: 30px;
				&-icon {
					&-default {
						width: 16px;
					}
				}
			}
		}
	}
}


/* footer */

.footer {
	background: #F4F5F7;
	padding-top: 100px;
	padding-bottom: 21px;
	&-cloud {
		display: none;
	}
	&-inner {
		display: flex;
		align-items: center;
		position: relative;
	}
	&-info {
		width: 27%;
		margin-right: 1.4%;
		&-text {
			font-family: "Gotham-Light";
			font-size: 10px;
			margin: 0 0 10px;
			padding: 0;
		}
		&-sns {
			margin: 0;
			padding: 0;
			display: flex;
			list-style-type: none;
			&-item {
				margin: 0 5px 0 0;
				&-link {
					display: block;
					height: 17px;
					width: 17px;
					background: #1A1A1A;
					color: #fff;
					font-size: 10px;
					position: relative;
					border-radius: 50%;
					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
					}
				}
			}
		}
	}
	&-menu {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 22%;
		&-block {
			margin: 0;
			padding: 0;
			&-title {
				font-size: 13px;
				font-family: "Gotham-Black";
				margin: 0 0 13px 0;
			}
			&-body {
				font-size: 13px;
				font-family: "Gotham-Light";
				margin: 0;
				padding: 0;
			}
		}
		&-list {
			margin: 0;
			padding: 0;
			list-style-type: none;
			&-item {
				a {
					cursor: pointer;
					color: #4D4D4D;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	&-logo {
		width: 182px;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		text-align: center;
	}
	&-bottom {
		&-text {
			font-size: 10px;
			text-align: center;
			margin: 67px 0 0 0;
			&--sp {
				display: none;
			}
		}
	}
}



@media screen and (max-width: 1349px) {
	.footer {
		&-inner {
			display: block;
			padding: 0 30px;
			max-width: initial;
			max-width: auto;
			box-sizing: border-box;
		}
		&-info {
			width: auto;
			margin-right: 0;
			margin-bottom: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			&-text {
				margin: 0;
				order: 2;
			}
			&-sns {
				margin: 0 12px 0 0;
				order: 1;
			}
		}
		&-menu {
			justify-content: center;
			width: auto;
			&-block {
				margin: 0 60px 0 0;
				padding: 0;
				&:last-child {
					margin: 0;
				}
			}
		}
		&-logo {
			text-align: right;
			position: static;
			transform: translateY(0);
			width: auto;
			img {
				width: 182px;
				display: inline-block;
			}
		}
		&-bottom {
			&-text {
				display: none;
				margin: 40px 0 50px 0;
				&--sp {
					display: block;
				} 
			}
		}
	}
}


@media screen and (max-width: 960px) {
	.footer {
    padding-top: 44px;
		padding-bottom: 40px;
		position: relative;
		&-cloud {
			position: absolute;
			bottom: 0;
			left: 0;
			transform: translate(-40%, 50%) scale(-1, 1);
			width: 100%;
			z-index: 0;
		}
		&-info {
			&-text {
				transform-origin: 0 50%;
				font-size: 10px;
				width: 130%;
			}
			&-sns {
				&-item {
					&-link {
						height: 24px;
						width: 24px;
						font-size: 16px;
						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%,-50%);
						}
					}
				}
			}
		}
		&-menu {
			justify-content: flex-start;
			&-block {
				margin: 0 48px 0 0;
				&-title {
					font-size: 10px;
				}
				&-body {
					font-size: 10px;
				}
			}
			&-list {
				margin: 0;
				padding: 0;
				list-style-type: none;
			}
		}
		&-logo {
			img {
				width: 32%;
				max-width: 154px;
			}
		}
		&-bottom {
			&-text {
				&--sp {
					font-size: 10px;
					text-align: left;
					margin: 25px 0 37px;
				}
			}
		}
	}

}


/* scroll & back btn */

.scroll-btn ,
.back-btn {
	height: 67px;
	width: 67px;
	background: #8BC53F;
	cursor: pointer;
	&--left {
		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8.5px 9px 8.5px 0;
			border-color: transparent #fff transparent transparent;
		}
	}
	&--bottom {
		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 9px 8.5px 0 8.5px;
			border-color: #fff transparent transparent transparent;
		}
	}
}

@media screen and (max-width: 960px) {
	.scroll-btn ,
	.back-btn {
		height: 33px;
		width: 33px;
		&--left {
			&::before {
				border-width: 4px 5px 4px 0;
			}
		}
		&--bottom {
			&::before {
				border-width: 4px 5px 4px 0;
			}
		}
	}
}


/* tooltip (region) */

.tooltip {
	background: #8BC53F !important;
	border-radius: 0% !important;
	font-family: "Gotham-Light" !important;
	font-size: 12px !important;
	padding: 16px 20px !important;
	opacity: 1 !important;
	strong {
		font-family: "Gotham-Bold" !important;
	}
}

/* submit (login) */

.btn {
	display: block;
	cursor: pointer;
	padding: 11px 30px;
	font-size: 12px;
	color: #fff;
	background: #8BC53F;
	border: none;
	outline: none;
	border-radius: 0.6250735521316528px;
	letter-spacing: 1.86px;
	margin: 0 10px;
	&:hover {
		background: #E6E6E6;
		color: #8BC53F;
	}
	&-group {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&--glay {
    background: #E5E5E5;
		color: #4D4D4D;
		&:hover {
			background: #8BC53F;
			color: #fff;
		}
	}
	&:disabled {
		cursor: not-allowed;
    background: #E5E5E5;
		color: #ccc;
		&:hover {
			background: #E5E5E5;
			color: #ccc;
		}
	}
}

@media screen and (max-width: 960px) {
	.btn {
		display: block;
		cursor: pointer;
		padding: 9px 26px;
		font-size: 10px;
		color: #fff;
		background: #8BC53F;
		border: none;
		outline: none;
		border-radius: 0.6250735521316528px;
		letter-spacing: 1.86px;
		margin: 0 10px;
		&:hover {
			background: #E6E6E6;
			color: #8BC53F;
		}
		&--glay {
			background: #E5E5E5;
			color: #4D4D4D;
			&:hover {
				background: #8BC53F;
				color: #fff;
			}
		}
	}
}


/* form item (login) */

.form {
	&-input {
		width: 100%;
		margin: 0 0 20px;
		input {
			font-family: 'Gotham-Book';
			width: 100%;
			font-size: 12px;
			padding: 9px 0;
			outline: none;
			border: none;
			border-bottom: 1px solid #8ac43f;
		}
	}
	&-label {
		display: block;
		font-size: 12px;
		margin: 0 0 16px 0;
		&--center {
			text-align: center;
		}
		a {
			color: #8bc53f;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	&-oauth {
		display: block;
		text-align: center;
		&-item {
			width: 40px;
			height: 40px;
			outline: none;
			border: none;
			margin: 5px 8px;
			cursor: pointer;
		}
	}
	&-check {
		$this: &;
		display: flex;
		font-size: 10px;
		align-items: center;
		input {
			margin: 0 13px 0 0;
			display: none;
			&:checked {
				+ #{$this} {
					&-label {
						#{$this}-label-icon {
							display: block;
							position: absolute;
							top: 50%;
							left: 3px;
							transform: translateY(-50%);
							color: #fff;
						}
						&::before {
							background: #8AC43F;
						}
					}
				}
			}
		}
		&-label {
			position: relative;
			padding-left: 27px;
			&-icon {
				display: none;
			}
			&::before {
				cursor: pointer;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				display: block;
				content: '';
				width: 14px;
				height: 14px;
				border: 1px solid #8AC43F;
			}
		}
	}
	&-select {
		border-bottom: 1px solid #8ac43f;
		select {
			width: 100%;
			font-family: 'Gotham-Book';
			width: 100%;
			font-size: 15px;
			padding: 9px 0;
			outline: none;
			border: none;
		}
	}
}

@media screen and (max-width: 960px) {
	.form {
		&-select {
			select {
				font-size: 12px;
			}
		}
	}
}

.topic-path {
	display: flex;
	list-style-type: none;
	padding: 0;
	margin: 0;
	&-item {
		position: relative;
		font-size: 12px;
		font-family: "Gotham-Book";
		cursor: pointer;
		padding: 0 30px 0 0;
		margin: 0 30px 0 0;
		&-active {
			cursor: auto;
			color: #83B12D;
		}
		svg {
			font-size: 10px;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			color: #83B12D;
		}
	}
	&-wrapper {
		display: none;
		padding: 16px 36px;
		width: 100%;
		box-sizing: border-box;
		background: #FAFAFA;
	}
}


@media screen and (max-width: 1590px) {
	.topic-path {
		&-wrapper {
			position: fixed;
			top: 150px;
			display: block;
			z-index: 9999;
			background: #fff;
		}
	}
}

@media screen and (max-width: 960px) {
	.topic-path {
		&-wrapper {
			top: 103px;
		}
		&-item {
			font-size: 10px;
			padding: 0 20px 0 0;
			margin: 0 15px 0 0;
		}
	}
}

.box-title {
	font-family: "Gotham-Black";
	font-size: 40px;
	color: #8BC53F;
	margin: 0 0 100px;
	padding: 0 0 0 103px;
	position: relative;
	&::before ,
	&::after {
		content: '';
		display: block;
		position: absolute;
	}
	&::before {
		width: 67px;
		height: 67px;
		background: #8BC53F;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	&::after {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 9px 8.5px 0 8.5px;
		border-color: #fff transparent transparent transparent;
		top: 50%;
		left: 25px;
		transform: translateY(-50%);
	}
	&-black {
		color: #fff;
		&::before {
			background: #4D4D4D;
		}
	}
}


@media screen and (max-width: 960px) {
	.box-title {
		font-size: 25px;
		margin: 0 0 20px;
		padding: 0 0 0 50px;
		&::before {
			width: 33px;
			height: 33px;
		}
		&::after {
			border-width: 5px 4.5px 0 4.5px;
			top: 55%;
			left: 12px;
		}
	}
}

.display-pc {
	display: block;
}

.display-sp {
	display: none;
}

@media screen and (max-width: 960px) {
	.display-pc {
		display: none;
	}
	.display-sp {
		display: block;
	}
}