@font-face {
	font-family: 'Gotham-Black';
	src: url(../../assets/fonts/Gotham-Black.otf);
}

@font-face {
	font-family: 'Gotham-Light';
	src: url(../../assets/fonts/Gotham-Light.otf);
}

@font-face {
	font-family: 'Gotham-Thin';
	src: url(../../assets/fonts/Gotham-Thin.otf);
}

@font-face {
	font-family: 'Gotham-Bold';
	src: url(../../assets/fonts/GothamBold.otf);
}

@font-face {
	font-family: 'Gotham-Book';
	src: url(../../assets/fonts/GothamBook.otf);
}

@font-face {
	font-family: 'Gotham-Light';
	src: url(../../assets/fonts/GothamLight.otf);
}

@font-face {
	font-family: 'Gotham-Medium';
	src: url(../../assets/fonts/GothamMedium.otf);
}

html {
	height: 100%;
}

body {
	font-family: 'Gotham-Book';
	background: #F4F5F7;
	color: #4D4D4D;
	margin: 0;
	padding: 0;
}

#root {
	overflow: hidden;
}

// SET TO RELATIVE AFTER TRANSITION
.route-section.pageSlider-enter-done {
		position: relative;
}

// common
.pageSlider-enter,
.pageSlider-enter-active,
.pageSlider-exit,
.pageSlider-exit-active {
	width: 100vw;
	height: 100vh;
	width: 100%;
	overflow: hidden;
}


.pageSlider-enter-active,
.pageSlider-exit-active {
	transition: .2s;
}

// toRight

.toRight .pageSlider-enter {
	position: absolute;
	transform: translate3d(100%, 0, 0);
}

.toRight .pageSlider-enter-active {
	transform: translate3d(0, 0, 0);
}

.toRight .pageSlider-exit {
	position: absolute;
	transform: translate3d(0, 0, 0);
}

.toRight .pageSlider-exit-active {
	position: absolute;
	transform: translate3d(-100%, 0, 0);
}

// toLeft
.toLeft .pageSlider-enter {
	position: absolute;
	transform: translate3d(-100%, 0, 0);
}

.toLeft .pageSlider-enter-active {
	transform: translate3d(0, 0, 0);
}

.toLeft .pageSlider-exit {
	position: absolute;
	transform: translate3d(0, 0, 0);
}

.toLeft .pageSlider-exit-active {
	position: absolute;
	transform: translate3d(100%, 0, 0);
}


// toTop
.toTop .pageSlider-enter {
	position: absolute;
	transform: translate3d(0, -100%, 0);
}

.toTop .pageSlider-enter-active {
	transform: translate3d(0, 0, 0);
}

.toTop .pageSlider-exit {
	position: absolute;
	transform: translate3d(0, 0, 0);
}

.toTop .pageSlider-exit-active {
	position: absolute;
	transform: translate3d(0, 100%, 0);
}


// toBottom
.toBottom .pageSlider-enter {
	position: absolute;
	transform: translate3d(0, 100%, 0);
}

.toBottom .pageSlider-enter-active {
	transform: translate3d(0, 0, 0);
}

.toBottom .pageSlider-exit {
	position: absolute;
	transform: translate3d(0, 0, 0);
}

.toBottom .pageSlider-exit-active {
	position: absolute;
	transform: translate3d(0, -100%, 0);
}

.display-pc {
	display: block;
}

.display-sp {
	display: none;
}

@media screen and (max-width: 960px) {
	.display-pc {
		display: none;
	}
	.display-sp {
		display: block;
	}
}