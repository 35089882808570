.terms {
	&-title {
		font-size: 40px;
		color: #8BC53F;
		padding: 0;
		margin: 0 0 124px;
		font-family: "Gotham-Black";
	}
	&-text {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 42px;
		&-item {
			width: 36%;
			min-width: 519px;
			> * {
				font-size: 12px;
				font-family: "Gotham-Light";
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.terms {
		&-title {
			font-size: 25px;
			padding: 0 0 0 33px;
			margin: 0 0 62px;
		}
		&-text {
			display: block;
			padding: 0;
			&-item {
				width: 100%;
				min-width: initial;
				min-width: auto;
				> * {
					font-size: 10px;
				}
			}
		}
	}
}