.order-plan {
	padding-top: 64px;
	padding-bottom: 200px;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: 100% auto;
	&-inner {
		position: relative;
	}
	&-cloud {
		position: absolute;
    top: -63px;
    right: 70px;
		width: 341px;
	}
}

.order-select {
	width: 100%;
	max-width: 514px;
	margin: 0 20px 0 0;
	&-title {
		margin: 0 0 218px;
	}
	&-wrapper {
		width: 100%;
		max-width: 1366px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}
	&-list {
		margin: 0;
		padding: 0;
	}
	&-item {
		$this: &;
		cursor: pointer;
		background: #E6E6E6;
		margin: 0 0 11px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 46px;
		font-family: "Gotham-Black";
		&--active {
			background: #8BC53F;
			color: #fff;
			#{$this} {
				&-name ,
				&-price {
					color: #fff;
				}
			}
		}
		&-name {
			color: #5D5C5C;
			font-size: 44px;
		}
		&-price {
			color: #83B12D;
			font-size: 27px;
		}
	}
	&-term {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&-text {
			font-size: 10px;
			font-family: "Gotham-Light";
			max-width: 246px;
		}
		&-input {
			width: 48px;
			height: 37px;
			text-align: center;
			font-size: 20px;
			font-family: "Gotham-Bold";
			color: #95C11F;
			padding: 0;
			border: none;
			margin: 0 18px;
			outline: none;
			&-wrapper {
				font-size: 14px;
				font-family: "Gotham-Book";
				strong {
					font-family: "Gotham-Bold";
				}
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.order-plan {
		padding-top: 8px;
		padding-bottom: 60px;
		background-size: 200% auto;
		&-cloud {
			display: none;
		}
	}
	
	.order-select {
		width: 100%;
		max-width: initial;
		max-width: auto;
		margin: 0 20px 0 0;
		&-title {
			margin: 0 0 20px;
		}
		&-wrapper {
			display: block;
		}
		&-item {
			margin: 0 0 11px;
			padding: 11px 26px;
			&-name {
				font-size: 25px;
			}
			&-price {
				font-size: 15px;
			}
		}
		&-term {
			padding: 0 9px;
			margin: 0 0 16px;
			&-text {
				font-size: 10px;
				max-width: 149px;
			}
			&-input {
				width: 25px;
				height: 19px;
				font-size: 10px;
				margin: 0 14px;
				outline: none;
				&-wrapper {
					font-size: 10px;
				}
			}
		}
	}
}

.order-create {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1380px;
	margin: 0 auto 48px;
	&--method {
		justify-content: center;
	}
	&-wrapper {
		position: relative;
		overflow: hidden;
	}
	&-cloud {
		position: absolute;
		bottom: -260px;
		left: -183px;
		width: 822px;
	}
	&-inner {
		padding: 0 0 260px;
	}
	&-account ,
	&-pay {
		width: 100%;
		max-width: 379px;
		margin: 0 20px 0 0;
		&-title {
			font-family: "Gotham-Bold";
			font-size: 15px;
			margin: 0 0 44px;
		}
		&-input {
			input {
				font-size: 15px;
			}
		}
	}
	&-account {
		&-tos {
			margin-top: 66px;
			&-text {
				font-size: 14px;
				a {
					color: #83B12D;
				}
			}
		}
	}
	&-method {
		background: #EBEBEB;
		padding: 15px 24px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 0 0 20px;
		box-sizing: border-box;
		&-item {
			margin: 0;
			display: flex;
			justify-content: space-between;
			width: 100%;
			font-size: 15px;
			&-name {
				font-family: "Gotham-Bold";
			}
			&-body {
				font-family: "Gotham-Book";
				margin: 0;
			}
		}
	}
	&-info {
		width: 100%;
		max-width: 379px;
		min-width: 379px;
		&-price {
			background: #EBEBEB;
			padding: 15px 24px;
			margin: 0 0 40px;
			&-item {
				display: flex;
				justify-content: space-between;
				margin: 0 0 5px;
				&:last-child {
					margin: 20px 0 0;
				}
				&-name ,
				&-price ,
				&-total {
					font-size: 15px;
				}
				&-name ,
				&-total {
					font-family: "Gotham-Bold";
				}
				&-price {
					font-family: "Gotham-Book";
				}
				&-total {
					color: #95C11F;
				}
			}
		}
		&-card {
			position: relative;
			background-size: contain;
			background-repeat: no-repeat;
			height: 226px;
			&-number ,
			&-expiry ,
			&-cvc {
				position: absolute;
				border: 1px solid #fff;
				padding: 8px;
			}
			&-number {
				width: 78%;
				bottom: 32%;
				left: 50%;
				transform: translateX(-50%);
				color: #fff;
				box-sizing: border-box;
				&-input {
					background: red;
				}
			}
			&-expiry {
				width: 20%;
				bottom: 14%;
				left: 11%;
			}
			&-cvc {
				width: 20%;
				bottom: 14%;
				left: 37.5%;		
			}
		}
	}
	&-btn {
		display: inline-block;
		padding: 12px 34px;
		font-size: 15px;
		font-family: "Gotham-Black";
		&-group {
			text-align: right;
			width: 100%;
			max-width: 1380px;
			margin: 0 auto;
			&--method {
				text-align: center;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.order-create {
		display: block;
		margin: 0 auto 70px;
		&-cloud {
			bottom: -76px;
			left: 50%;
			transform: translateX(-50%);
			width: 260px;
		}
		&-inner {
			padding: 0 0 180px;
		}
		&-account ,
		&-pay {
			max-width: initial;
			max-width: auto;
			padding: 0 31px;
			box-sizing: border-box;
			margin: 0 20px 0 0;
			&-title {
				font-size: 12px;
				margin: 30px 0 44px;
			}
			&-input {
				input {
					font-size: 12px;
				}
			}
		}
		&-account {
			margin: 0 0 46px;
			&-tos {
				margin-top: 22px;
				&-text {
					font-size: 10px;
				}
			}
		}
		&-method {
			&-item {
				margin: 0 0 15px;
				display: block;
				&:last-child {
					margin: 0;
				}
			}
		}
		&-pay {
			margin: 0 0 40px;
		}
		&-info {
			max-width: initial;
			max-width: auto;
			min-width: initial;
			min-width: auto;
			&-price {
				margin: 0 0 32px;
				&-item {
					margin: 0 0 5px;
				}
			}
			&-card {
				height: 180px;
				width: 303px;
				margin: 0 auto;
			}
		}
		&-btn {
			padding: 7px 18px;
			font-size: 10px;
			&-group {
				text-align: center;
			}
		}
	}
}