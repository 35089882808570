.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index: 10001;
	opacity: 1;
	animation: loadingOpen .8s;
	&-close {
		animation: loadingClose .8s;
	}
	&-logo {
		width: 80px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		vertical-align: bottom;
		&-end {
			animation: logoEnd .8s;
		}
		&-finish {
			width: 100px;
		}
	}
	&-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}
	&-progress {
		height: 1px;
		background: #EAEAEA;
		width: 147px;
		position: absolute;
		top: 70px;
		left: 50%;
		transform: translateX(-50%);
		&-end {
			animation: progressFadeOut .8s;
		}
		&-finish {
			display: none;
		}
		&-bar {
			background: #86BD41;
			width: 1%;
			position: absolute;
			left: 0px;
			top: 0px;
			height: 1px;
			&-start {
				width: 100%;
				animation: progressAnimation .8s;
			}
		}
	}
}

.login-loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: #fff;
	z-index: 9999;
	opacity: 1;
	&-logo {
		width: 100px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		vertical-align: bottom;
	}
	&--fade {
		&-out {
			animation: loading .8s;
		}
		&-comp {
			z-index: -1;
		}
	}
}


@keyframes loading {
	0% {
			opacity: 1;
	}
	20% {
			opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes loadingOpen {
	0% {
			opacity: 0;
	}
	20% {
			opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


@keyframes loadingClose {
	0% {
			opacity: 1;
	}
	20% {
			opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes progressAnimation {
	0% {
		width: 1%;
	}
	100% {
		width: 100%;
	} 
}

 @keyframes progressFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	} 
 }
 
 @keyframes logoEnd {
	0% {
		width: 80px;
	}
	100% {
		width: 100px;
	} 
 }

 