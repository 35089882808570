.modal {
	&-wrapper {
		position: relative;
		z-index: 9999;
		animation: fadein .6s normal;
		&-close {
			opacity: 0;
			animation: fadeout .6s normal;
		}
	}
	&-layer {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #696868;
		opacity: .89;
		z-index: 9999;
	}
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 36%;
	background: #fff;
	z-index: 10000;
	opacity: 1;
	&-header {
		position: relative;
		vertical-align: bottom;
		&-bg {
			width: 100%;
		}
		&-title {
			width: 100%;
			position: absolute;
			bottom: 18%;
			text-align: center;
			color: #FFFFFF;
			font-family: "Gotham-Black";
			font-size: 36px;
			margin: 0;
			&-success {
				bottom: 24%;
			}
		}
		&-img {
			position: absolute;
			bottom: 50%;
			left: 50%;
			transform: translateX(-50%);
			width: 28%;
			&-success {
				width: 22%;
				bottom: 45%;
			}
		}
	}
	&-text {
		font-family: "Gotham-Book";
		font-size: 13px;
		text-align: center;
		margin: 40px 0;
		* {
			margin: 0;
		}
		strong {
			font-family: "Gotham-Bold";
		}
		a {
			color: #88BE42;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&-btns {
		padding-bottom: 60px;
		border: none;
		outline: none;
	}
	&-close {
		cursor: pointer;
		position: absolute;
		top: 14px;
		right: 10px;
		background: no-repeat;
		border: none;
		color: #fff;
		outline: none;
	}
}


@media screen and (max-width: 960px) {
	.modal {
		&-wrapper {
			position: relative;
			z-index: 9999;
			animation: fadein .6s normal;
			&-close {
				opacity: 0;
				animation: fadeout .6s normal;
			}
		}
		&-layer {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #696868;
			opacity: .89;
			z-index: 9999;
		}
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		background: #fff;
		z-index: 10000;
		opacity: 1;
		&-header {
			position: relative;
			vertical-align: bottom;
			&-bg {
				width: 100%;
			}
			&-title {
				width: 100%;
				position: absolute;
				bottom: 20%;
				text-align: center;
				color: #FFFFFF;
				font-family: "Gotham-Black";
				font-size: 18px;
				margin: 0;
			}
			&-img {
				position: absolute;
				bottom: 50%;
				left: 50%;
				transform: translateX(-50%);
				width: 28%;
				&-success {
					width: 20%;
					bottom: 40%;
				}
			}
		}
		&-text {
			font-family: "Gotham-Book";
			font-size: 10px;
			text-align: center;
			margin: 40px 0;
			* {
				margin: 0;
			}
			strong {
				font-family: "Gotham-Bold";
			}
		}
		&-btns {
			margin-bottom: 60px;
		}
		&-close {
			cursor: pointer;
			position: absolute;
			top: 14px;
			right: 10px;
			background: no-repeat;
			border: none;
			color: #fff;
			outline: none;
		}
	}
}


@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}