.blog {
	&-title {
		font-family: "Gotham-Black";
		font-size: 40px;
		margin: 0 0 30px;
		color: #8BC53F;
	}
	&-container {
		padding-top: 78px;
	}
	&-list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 20px;
	}
	&-item {
		width: 25%;
		position: relative;
		box-sizing: border-box;
		$this: &;
		&:hover {
			#{$this} {
				&-inner {
					opacity: 1;
				}
			}
		}
		&:nth-child(10n + 1) {
			margin: 0 25% 0 50%;
		}
		&:nth-child(10n + 6) {
			margin: 0 0 0 25%;
		}
		&:nth-child(10n + 7) {
			margin: 0 25% 0 0;
		}
		&:nth-child(10n + 8) {
			margin: 0 0 0 25%;
		}
		&:nth-child(10n + 9) {
			margin: 0 0 0 25%;
		}
		&:nth-child(10n) {
			margin: 0 25% 0 50%;
		}
		&-inner {
			position: absolute;
			top: 0;
			left: 0;
			padding: 19%;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			background: rgba(77, 77, 77, 0.9);
			overflow: hidden;
			color: #fff;
			opacity: 0;
			transition: opacity .6s;
		}
		&:nth-child(2n+1) {
			#{$this} {
				&-inner {
					background: rgba(133,191,63,.9);
				}
			}
		}
		&-title {
			font-family: "Gotham-Bold";
			font-size: 20 / 1920 * 100vw;
			margin: 0 0 12px;
			padding: 0;
		}
		&-date {
			display: inline-block;
			font-family: "Gotham-Bold";
			font-size: 13 / 1920 * 100vw;
			background: #fff;
			padding: 4px 8px 2px;
			color: #85BF3F;
			margin: 0 0 12px;
		}
		&-desc {
			font-size: 12 / 1920 * 100vw;
			margin: 0 0 24px;
		}
		&-more {
			font-family: "Gotham-Bold";
			font-size: 13 / 1920 * 100vw;
			margin: 0;
			padding: 0;
		}
		&-img {
			width: 100%;
			vertical-align: bottom;
			&-sp {
				display: none;
			}
		}
	}
	&-btn {
		&-group {
			display: flex;
			justify-content: center;
			padding: 30px 0;
		}
	}
	&-no-article {
		font-size: 20px;
		padding: 0 30px 50px;
		text-align: center;
		margin: 0;
		font-family: "Gotham-Black";
		color: #8BC53F;
	}
}


@media screen and (max-width: 960px) {
	.blog {
		&-title {
			font-size: 25px;
			margin: 0 0 48px;
			padding: 0 36px;
		}
		&-container {
			padding: 10px 0px;
		}
		&-item {
			width: 100%;
			$this: &;
			&:hover {
				#{$this} {
					&-inner {
						opacity: 1;
					}
				}
			}
			&:nth-child(10n + 1) ,
			&:nth-child(10n + 6) ,
			&:nth-child(10n + 7) ,
			&:nth-child(10n + 8) ,
			&:nth-child(10n + 9) ,
			&:nth-child(10n) {
				margin: 0;
			}
			&-container {
				display: flex;
				background: rgba(77, 77, 77, 1);
				justify-content: flex-end;
			}
			&-inner {
				padding: 10px 44px;
				width: 62%;
				background: none;
				opacity: 1;
				transition: none;
				display: flex;
				align-items: center;
			}
			&:nth-child(2n+1) {
				#{$this} {
					&-inner {
						background: none;
						left: auto;
						right: 0;
					}
					&-container {
						justify-content: flex-start;
						background: rgba(133,191,63,1);
					}
				}
			}
			&-title {
				font-family: "Gotham-Bold";
				font-size: 13px;
				margin: 0;
			}
			&-date ,
			&-desc ,
			&-more {
				display: none;
			}
			&-img {
				display: none;
				&-sp {
					display: block;
					background-size: cover;
					width: 38%;
					vertical-align: bottom;
					&-img {
						width: 100%;
						display: block;
					}
				}
			}
		}
	}
}



// article

.blog-article {
	&-wrapper {
		display: flex;
		background: #fff;
	}
	&-back {
		position: fixed;
		top: 210px;
		left: 7%;
	}
	&-info {
		width: 38%;
		padding: 300px 1.6% 0 14%;
		box-sizing: border-box;
		background-size: cover;
		&-title {
			font-family: "Gotham-Bold";
			font-size: 42px;
			color: #fff;
			margin: 0 0 45px;
		}
		&-date {
			font-family: "Gotham-Bold";
			font-size: 28px;
			background: #fff;
			color: #4D4D4D;
			padding: 8px 22px 4px;
		}
	}
	&-contents {
		width: 62%;
		padding: 300px 14%;
		box-sizing: border-box;
		* {
			font-family: "Gotham-Light";
			font-size: 14px;
		}
	}
}

@media screen and (max-width: 960px) {
	.blog-article {
		&-wrapper {
			display: block;
		}
		&-back {
			top: 170px;
			left: 37px;
		}
		&-info {
			width: 100%;
			padding: 123px 37px 112px;
			&-title {
				font-size: 23px;
				margin: 0 0 24px;
			}
			&-date {
				font-size: 10px;
				padding: 2px 7px 1px;
			}
		}
		&-contents {
			width: 100%;
			padding: 67px 37px;
			* {
				font-size: 11px;
			}
		}
	}
}