.product-hero {
	display: flex;
	align-items: center;
	padding: 130px 0;
	&-wrapper {
		background: transparent linear-gradient(90deg, #fff 0%,#FFFFFF00 19%, #F8F8F83D 28%, #F2F2F282 37%, #ECECECB8 51%, #E9E9E9DF 59%, #E6E6E6F6 70%, #E6E6E6 78%, #E6E6E6 100%) 0% 0% no-repeat padding-box;
	}
	&-img {
		margin: 0 74px 0 0;
		padding: 0;
		width: 261px;
	}
	&-info {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		&-title {
			font-size: 120px;
			font-family: "Gotham-Black";
			color: #8BC53F;
			margin: 0 173px 0 0;
		}
		&-text {
			font-family: "Gotham-Black";
			font-size: 28px;
			color: #8BC53F;
			margin: 0;
		}
	}
}


@media screen and (max-width: 1201px) {
	.product-hero {
		justify-content: center;
		padding: 24px 0;
		&-wrapper {
			background: #fff;
		}
		&-img {
			margin: 0 9% 0 0;
			width: 34%;
		}
		&-info {
			display: block;
			margin: 0 2% 0 0;
			&-title {
				font-size: 10.6vw;
				margin: 0 0 7px 0;
			}
			&-text {
				font-size: 2.6vw;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.product-hero {
		&-info {
				width: 55%;
		}
	}
}

.product-buy {
	width: 65%;
	max-width: 1251px;
	margin: 0 auto;
	padding: 0 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	&-wrapper {
		background: #fff;
		padding: 140px 0;
	}
	&-spec {
		width: 100%;
		max-width: 500px;
		display: flex;
		flex-wrap: wrap;
	}
	&-group {
		width: 100%;
		max-width: 426px;
		padding-left: 20px;
		&-text {
			margin: 0 0 80px;
			padding: 0 106px 0 0;
			font-family: "Gotham-Light";
			font-size: 12px;
			line-height: 1.16;
		}
		&-price {
			font-family: "Gotham-Black";
			font-size: 52px;
			color: #8AC43F;
			margin: 0 0 30px;
		}
		&-purchase {
			display: flex;
			justify-content: space-between;
			&-btn {
				cursor: pointer;
				padding: 15px;
				background: #8BC53F;
				width: 216px;
				font-family: "Gotham-Black";
				letter-spacing: .2em;
				color: #fff;
				border-radius: 12px;
				border: none;
				outline: none;
				transition: .6s;
				&:hover {
					color: #8BC53F;
					background: #F0F0F0;
				}
			}
		}
		&-period {
			display: flex;
			align-items: center;
			&-name {
				font-size: 10px;
			}
			&-btn {
				margin: 0 13px;
				vertical-align: bottom;
				line-height: 1;
				height: 26px;
				cursor: pointer;
				position: relative;
				&-input {
					display: none;
					&:checked {
						+ .product-buy-group-period-btn-icon {
							left: calc(100% - 22px);
						}
					}
				}
				&-wrapper {
					display: inline-block;
					width: 60px;
					height: 26px;
					border: 2px solid #808080;
					border-radius: 13px;
					box-sizing: border-box;
				}
				&-icon {
					position: absolute;
					left: 4px;
					top: 4px;
					background: #808080;
					border-radius: 50%;
					width: 18px;
					height: 18px;
					transition: left .4s;
				}
			}
		}
	}
}

@media screen and (max-width: 1590px) {
	.product-buy {
		width: 100%;
		max-width: initial;
		max-width: auto;
		padding: 0 36px;
	}
}


@media screen and (max-width: 960px) {
	.product-buy {
		width: 100%;
		margin: 0 auto;
		display: block;
		&-wrapper {
			padding: 0 0 50px;
		}
		&-spec {
			width: 240px;
			max-width: initial;
			max-width: auto;
			margin: 0 auto 26px;
		}
		&-group {
			width: 100%;
			max-width: initial;
			max-width: auto;
			padding: 0 30px;
			box-sizing: border-box;
			&-text {
				margin: 0 0 0;
				padding: 0 0 27px;
				font-size: 10px;
			}
			&-price {
				font-size: 31px;
				margin: 0 0 14px;
				text-align: center;
			}
			&-purchase {
				display: block;
				text-align: center;
				&-btn {
					width: 122px;
					transition: .6s;
					font-size: 10px;
				}
			}
			&-period {
				justify-content: center;
				margin: 0 0 30px;
				&-btn {
					cursor: pointer;
					position: relative;
				}
			}
		}
	}
}

.product-features {
	&-wrapper {
		padding-top: 214px;
		padding-bottom: 240px;
	}
	&-list {
		display: flex;
		margin: 0;
		padding: 0;
	}
	&-item {
		display: flex;
		align-items: center;
		width: 25%;
		&-img {
			&--delivered {
				width: 62px;
				margin-right: 42px;
			}
			&--ddos {
				width: 54px;
				margin-right: 42px;
			}
			&--technical {
				width: 62px;
				margin-right: 43px;
			}
			&--premium {
				width: 63px;
				margin-right: 40px;
			}
		}
		&-text {
			margin: 0;
			padding: 0;
			font-family: "Gotham-Bold";
			color: #FFFFFF;
			font-size: 21px;
		}
	}
}

@media screen and (max-width: 1050px) {
	.product-features {
		&-wrapper {
			padding-top: 80px;
			padding-bottom: 52px;
		}
		&-list {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}
		&-item {
			width: 50%;
			margin: 0 0 18px;
		}
	}
}

@media screen and (max-width: 960px) {
	.product-features {
		&-wrapper {
			padding-top: 28px;
			padding-bottom: 52px;
		}
		&-title {
			font-size: 25px;
			margin: 0 0 49px;
			padding: 0 0 0 50px;
			&::before {
				width: 33px;
				height: 33px;
			}
			&::after {
				border-width: 5px 4.5px 0 4.5px;
				left: 12.5px;
			}
		}
		&-list {
			display: block;
			width: 100%;
		}
		&-item {
			width: 132px;
			margin: 0 auto 18px;
			&:last-child {
				margin: 0 auto;
			}
			&-img {
				&--delivered {
					width: 34px;
					margin-right: 24px;
				}
				&--ddos {
					width: 30px;
					margin-right: 26px;
				}
				&--technical {
					width: 34px;
					margin-right: 24px;
				}
				&--premium {
					width: 38px;
					margin-right: 22px;
				}
			}
			&-text {
				font-size: 12px;
			}
		}
	}
}


.product-use {
	&-wrapper {
		padding-top: 177px;
		padding-bottom: 86px;
	}
	&-title {
		color: #8AC43F;
		width: 342px;
		margin: 0;
		padding-top: 74px;
		padding-bottom: 74px;
		box-sizing: border-box;
	}
	&-list {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 1288px;
		margin: 0 auto;
		&-wrapper {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 0 120px;
		}
	}
	&-item {
		width: 274px;
		height: 192px;
		background-repeat: no-repeat;
		text-align: center;
		font-size: 21px;
		font-family: "Gotham-Bold";
		color: #fff;
		box-sizing: border-box;
		padding-top: 88px;
		margin-right: 24px;
		margin-left: 24px;
		margin-bottom: 20px;
		&:first-child {
			padding-top: 102px;
		}
	}
	&-text {
		display: flex;
		padding: 0 10%;
		&-item {
			&:first-child {
				width: 49%;
				margin-right: 5%;
			}
			&:last-child {
				width: 46%;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.product-use {
		&-wrapper {
			padding-top: 43px;
			padding-bottom: 31px;
		}
		&-title {
			font-size: 25px;
			margin: 0 0 49px;
			padding: 0 0 0 50px;
			&::before {
				width: 33px;
				height: 33px;
			}
			&::after {
				border-width: 5px 4.5px 0 4.5px;
				left: 12.5px;
			}
		}
		&-list {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			width: 1288px;
			margin: 0 auto;
			&-wrapper {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin: 0 0 20px;
			}
		}
		&-item {
			width: 136px;
			height: 95px;
			background-repeat: no-repeat;
			text-align: center;
			font-size: 10px;
			font-family: "Gotham-Bold";
			color: #fff;
			box-sizing: border-box;
			padding-top: 48px;
			margin-right: 6px;
			margin-left: 6px;
			margin-bottom: 30px;
			&:first-child {
				padding-top: 50px;
			}
		}
		&-text {
			display: block;
			padding: 0 0;
			font-size: 10px;
			&-item {
				&:first-child {
					width: 100%;
					margin-right: 0;
				}
				&:last-child {
					width: 100%;
				}
			}
		}
	}
}