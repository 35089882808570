.login {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 20px 0;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	box-sizing: border-box;
	&-inner {
		width: 38%;
		min-width: 414px;
	}
	&-title {
		color: #fff;
		font-family: "Gotham-Black";
		font-size: 131px;
		margin: 0 0 30px;
		padding: 0;
		font-weight: normal;
	}
	&-scroll-btn {
		position: absolute;
		top: 150px;
		left: 140px;
	}
	&-btn {
		&-group {
			margin-top: 60px;
		}
	}
	&-form {
		background: #fff;
		border-radius: 8px;
		padding: 62px 84px;
		box-sizing: border-box;
		&-label {
			&-oauth {
				margin: 40px 0 10px;
			}
		}
		&-input {
			margin: 0 0 32px;
		}
		&-oauth {
			margin-bottom: 0;
		}
	}
}


@media screen and (max-width: 960px) {
	.login {
		padding: 80px 31px;
		&-inner {
			width: 100%;
			min-width: initial;
			min-width: auto;
		}
		&-title {
			font-size: 99px;
			margin: 0 0 14px;
		}
		&-scroll-btn {
			position: absolute;
			top: 31px;
			left: 36px;
		}
		&-btn {
			&-group {
				margin-top: 30px;
			}
		}
		&-form {
			padding: 32px;
			&-label {
				&-oauth {
					margin: 40px 0 10px;
				}
			}
			&-input {
				margin: 0 0 32px;
			}
			&-oauth {
				margin-bottom: 0;
			}
		}
	}
	
}