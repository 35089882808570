.contact {
	&-title {
		font-size: 40px;
		color: #8BC53F;
		padding: 0;
		margin: 0 0 124px;
		font-family: "Gotham-Black";
	}

	&-input {
		width: 100%;
	}

	&-message-margin {
		margin-top: 30px !important;
	}

	&-label {
		font-family: "Gotham-Book";
		font-size: 12px;
	}

	&-text {
		font-size: 10px;
	}

	&-button {
		cursor: pointer;
		padding: 11px 30px;
		font-size: 12px;
		color: #fff;
		background: #8BC53F;
		border: none;
		outline: none;
		border-radius: 0.6250735521316528px;
		letter-spacing: 1.86px;
		text-transform: uppercase;
		&:hover {
			background: #E6E6E6;
			color: #8BC53F;
		}

		&-container {
			display: flex;
			align-items: flex-end;
			width: 100%;
			height: 100%;
		}
	}
}

@media screen and (max-width: 960px) {
	.contact {
		&-title {
			font-size: 25px;
			padding: 0 0 0 33px;
			margin: 0 0 62px;
		}
	
		&-button {
			&-container {
				align-items: flex-start;
			}
		}
	}
}